/* eslint-disable no-param-reassign */
const { default: axios } = require('axios');
const { createApp } = require('vue/dist/vue.esm-bundler.js');

const sivOptions = {
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
};

createApp({
    delimiters: ['((', '))'],
    data() {
        return {
            items: [],
        };
    },
    mounted() {
        // Sync then, then execute main action (which needs time) then plan it
        this.syncTime().then(this.main);
        setInterval(this.main, 15 * 1000);
        setInterval(this.syncTime, 10 * 1000);
        setInterval(() => {
            this.currentTs += 5000;
        }, 5000);
    },
    methods: {
        main() {
            axios
                .get(`/data/${window.preloaded.timelineId}`)
                .then((response) => {
                    let scrollCandidate = null;
                    let scrollCandidateDiff = Infinity;

                    const now = new Date(this.currentTs);
                    this.items = response.data
                        .map((item) => {
                            const split = item.time.split('h');
                            const time = new Date(this.currentTs);
                            time.setHours(split[0]);
                            time.setMinutes(split[1]);

                            const diff = Math.abs(now - time);

                            if (diff < scrollCandidateDiff) {
                                scrollCandidate = item;
                                scrollCandidateDiff = diff;
                            }

                            item.diff = now - time;
                            return item;
                        })
                        .map((item) => {
                            // All those before the scroll candidate shoud have an opacity
                            item.past = item.diff > scrollCandidateDiff;
                            return item;
                        });

                    this.$nextTick(() => {
                        const el = document.getElementById(
                            scrollCandidate.slug
                        );

                        if (!el) {
                            console.log('strange, no el!');
                            return;
                        }

                        el.scrollIntoView(sivOptions);

                        // Calling this a second time because Chrome does not always catch the first
                        setTimeout(() => {
                            el.scrollIntoView(sivOptions);
                        }, 1000);
                    });
                });
        },

        syncTime() {
            return axios
                .get('/time')
                .then((response) => {
                    this.currentTs = response.data.ts;
                })
                .catch(() => {
                    this.currentTs = 0;
                });
        },
    },
}).mount('#app');
